:root {
    --purple: #782bb2;
    --lightPurple: #efe6f6;
    --brownishGray: #757575;
    --grayish: rgba(189, 189, 189, 0.12);
}

@font-face {
    font-family: SFProDisplay-Regular;
    src: url(../fonts/SF-Pro-Display-Regular.otf);
    src: url(../fonts/SFProDisplay-Regular.eot);
    src: url(../fonts/SFProDisplay-Regular.eot?#iefix) format('embedded-opentype'),
    url(../fonts/SFProDisplay-Regular.woff) format('woff'),
    url(../fonts/SFProDisplay-Regular.woff2) format('woff2'),
    url(../fonts/SFProDisplay-Regular.ttf) format('truetype'),
    url(../fonts/SFProDisplay-Regular.svg#Sri-TSCRegular) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: SFProDisplay-Semibold;
    src: url(../fonts/SF-Pro-Display-Semibold.otf);
    src: url(../fonts/SFProDisplay-Semibold.eot);
    src: url(../fonts/SFProDisplay-Semibold.eot?#iefix) format('embedded-opentype'),
    url(../fonts/SFProDisplay-Semibold.woff) format('woff'),
    url(../fonts/SFProDisplay-Semibold.woff2) format('woff2'),
    url(../fonts/SFProDisplay-Semibold.ttf) format('truetype'),
    url(../fonts/SFProDisplay-Semibold.svg#Sri-TSCRegular) format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: SFProDisplay-Bold;
    src: url(../fonts/SF-Pro-Display-Bold.otf);
    src: url(../fonts/SFProDisplay-Bold.eot);
    src: url(../fonts/SFProDisplay-Bold.eot?#iefix) format('embedded-opentype'),
    url(../fonts/SFProDisplay-Bold.woff) format('woff'),
    url(../fonts/SFProDisplay-Bold.woff2) format('woff2'),
    url(../fonts/SFProDisplay-Bold.ttf) format('truetype'),
    url(../fonts/SFProDisplay-Bold.svg#Sri-TSCRegular) format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: SFProDisplay-Medium;
    src: url(../fonts/SF-Pro-Display-Medium.otf);
    src: url(../fonts/SFProDisplay-Medium.eot);
    src: url(../fonts/SFProDisplay-Medium.eot?#iefix) format('embedded-opentype'),
    url(../fonts/SFProDisplay-Medium.woff) format('woff'),
    url(../fonts/SFProDisplay-Medium.woff2) format('woff2'),
    url(../fonts/SFProDisplay-Medium.ttf) format('truetype'),
    url(../fonts/SFProDisplay-Medium.svg#Sri-TSCRegular) format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: .1fr 14fr .1fr;
    grid-template-rows: auto;
    font-family: SFProDisplay-Regular, sans-serif;
}

#root {
    margin: 1em 0;
}

#main {
    position: relative;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0.1em;
    border: 1px solid var(--lightPurple);
    border-top: none;
    font-size: 90%;
}

th {
    text-align: left;
    background-color: var(--purple);
    color: white;
    padding: .5em;
    user-select: none;
    border: none;
    font-weight: normal;
}

th.sortable {
    cursor: pointer;
}

td {
    padding: .3em;
    color: #757575;
    overflow: hidden;
    max-width: 290px;
}

thead tr {
    border: 1px solid var(--purple);
}

tbody tr {
    border-top: 1px dotted var(--lightPurple);
    cursor: pointer;
}

tr:hover {
    background-color: var(--lightPurple);
    border: 1px solid var(--purple)
}

tr:hover td {
    color: black;
}

colgroup.sorted {
    background-color: var(--lightPurple);
}

nav > a {
    display: inline-block;
    padding: 0 .4em;
    margin-right: .5em;
    line-height: 2em;
    border: 1px solid var(--purple);
    border-bottom: none;
    font-size: larger;
    text-decoration: none;
}

nav.tabs > a {
    margin-right: 1em;
}

nav.tabs > a .icon {
    margin-right: .5em;
    vertical-align: middle;
}

nav.tabs > a.active {
    background-color: var(--purple);
    color: white;
}

nav.flat {
    padding-top: 1em;
    background-color: var(--purple);
    border-bottom: 1px solid var(--lightPurple);
}

nav.flat > a {
    margin: 1em .2em 0 .2em;
    color: var(--lightPurple);
    opacity: .7;
}

nav.flat > a small {
    font-size: small;
    margin-left: .4em;
    opacity: .5;
}

nav.flat > a.active {
    opacity: 1;
    border-color: var(--lightPurple);
    border-bottom-color: var(--purple);
}

#status {
    text-align: right;
    position: absolute;
    top: 50px;
    right: 20px;
    font-size: small;
    color: var(--lightPurple);
    opacity: .5;
    width: 150px;
}

#login {
    width: 350px;
    margin: 2em auto;
}

#login button {
    float: right
}

fieldset {
    padding: 0;
    border: none;
}

input[type=text] {
    border: 2px solid var(--purple);
    font-size: x-large;
    margin-top: .3em;
    padding: .3em;
    width: 95%;
}

fieldset {
    color: var(--purple);
}

button {
    border: 1px solid var(--purple);
    text-transform: uppercase;
    background-color: white;
    color: var(--purple);
    font-family: SFProDisplay-Regular, sans-serif;
    padding: 1em;
    font-size: 14px;
    letter-spacing: .1em;
    margin: 1em 0;
    cursor: pointer;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

button.primary {
    background-color: var(--purple);
    color: white;
}

button.small, dl button {
    padding: .2em 1em;
    letter-spacing: normal;
    font-size: small;
    margin: .2em;
}

button#logout {
    position: absolute;
    top: 0;
    right: 0;
}

dl button {
    float: right;
    margin: 0 .2em;
    border: none;
    outline: none;
}

#additional {
    border-top: 1px solid #782bb2;
}

#loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(203, 203, 203, 0.7);
    z-index: 10;
}

#loader div {
    position: absolute;
    top: 45%;
    left: 45%;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #782bb2;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.multi-picker {
    cursor: pointer;
    height: 25px;
}

.multi-picker .icon {
    font-size: small;
    float: right;
}

.multi-picker input {
    display: inline-block;
}

.multi-picker span {
    margin-right: 1em;
}

#global-search {
    position: absolute;
    top: 80px;
    right: 10px;
}

#global-search input {
    width: 150px;
    background: transparent;
    border: 1px solid var(--lightPurple);
    padding: .3em;
    color: var(--lightPurple);
}

#global-search input::placeholder {
    color: var(--lightPurple);
    opacity: 1
}

#global-search input:focus {
    outline: none;
}
