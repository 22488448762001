/*--------------------------------

sc-admin Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'sc-admin';
  src: url('../fonts/sc-admin.eot');
  src: url('../fonts/sc-admin.eot') format('embedded-opentype'), url('../fonts/sc-admin.woff2') format('woff2'), url('../fonts/sc-admin.woff') format('woff'), url('../fonts/sc-admin.ttf') format('truetype'), url('../fonts/sc-admin.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'sc-admin';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icon-16 {
  font-size: 16px;
}
.icon-32 {
  font-size: 32px;
}

/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}
@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  transform: rotate(90deg);
}
.icon-rotate-180 {
  transform: rotate(180deg);
}
.icon-rotate-270 {
  transform: rotate(270deg);
}
.icon-flip-y {
  transform: scaleY(-1);
}
.icon-flip-x {
  transform: scaleX(-1);
}
/*------------------------
	icons
-------------------------*/

.icon-office-2::before {
  content: "\ea03";
}

.icon-users-ww::before {
  content: "\ea04";
}

.icon-pen::before {
  content: "\ea05";
}

.icon-bin::before {
  content: "\ea06";
}

.icon-e-remove::before {
  content: "\ea07";
}

.icon-key::before {
  content: "\ea08";
}

.icon-down::before {
  content: "\ea09";
}

.icon-f-check::before {
  content: "\ea0a";
}

.icon-data-download::before {
  content: "\ea0b";
}
