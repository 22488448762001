#modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

#modal .content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 3em;
    border: 1px solid #888;
    width: 65%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr auto 1fr;
    grid-gap: 1em;
    grid-template-areas:
            "header header"
            "column1 column2"
            "actions actions"
}

#modal .content h1 {
    grid-area: header;
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
}

#modal .content > dl {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
}

#modal .content > dl > dt {
    color: #88888866;
    align-self: end;
    text-align: right;
}

#modal .content > dl > dd, #modal .content dl input {
    padding: .5em 0 0 1em;
    color: black;
    border-bottom: 1px dotted #8888884a;
    margin-inline-start: 0;
    align-self: end;
    font-size: x-large;
    font-family: monospace;
}

#modal .content > dl > dd small {
    font-size: 70%;
    opacity: .5;
}

#modal .content > dl > dd small.newline {
    display: block;
}

#modal .content > dl input {
    border: none;
    padding: 0;
    outline: none;
    width: 80%;
}

#modal .content > dl select {
    width: 100%;
    border: none;
    font-size: x-large;
    font-family: monospace;
}

#modal .content > dl select:focus {
    outline: none;
}

#modal .content dt:after {
    content: ':';
}

#modal .content div.actions {
    grid-area: actions;
    text-align: end;
}

#modal .content div.actions button {
    margin: 1em;
    background: none;
    padding: 1em;
    border: 1px solid var(--purple);
    font-family: SFProDisplay-Regular, sans-serif;
    color: var(--purple);
    font-size: medium;
}

